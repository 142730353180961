import "sources/styles/modules/cartForm.scss";
import { overlay } from "./overlay";
import { IApiResult } from "../Api/apiResult";
import { masked } from "../../helpers/helpers";
import {Evt} from "../../helpers/DOM/Evt";

function findAttributeValueInTreeParents(el: HTMLElement, attributeFullName) {
    let foundEl = null;
    const selector = `[${attributeFullName}]`;

    if (el.matches(selector)) {
        foundEl = el;
    } else if (el.closest(selector) !== null) {
        foundEl = el.closest(selector);
    }

    if (foundEl === null) {
        return [null, null];
    }

    return [foundEl.getAttribute(attributeFullName), foundEl]
}

function setButtonIsDisabled(el: Element, isDis: boolean) {
    if (isDis) {
        el.setAttribute('disabled', 'disabled');
    } else {
        el.removeAttribute('disabled');
    }
}

class CartForm {
    private wrapper: Element;
    private button: Element;
    private headerBtn: Element;
    private popupRoot: Element;
    private cartTotal: Element;
    private checkoutList: Element;

    constructor() {
        this.onBuyButtonClick = this.onBuyButtonClick.bind(this);
        this.onRemoveButtonClick = this.onRemoveButtonClick.bind(this);
        this.onCheckoutRemoveButtonClick = this.onCheckoutRemoveButtonClick.bind(this);
    }

    init() {
        this.wrapper = document.querySelector("#popup-cart-wrapper");
        this.popupRoot = document.querySelector("#cartPopupRoot");
        this.headerBtn = document.querySelector("#header-cart-button");
        this.cartTotal = document.querySelector("#cart-total");
        this.checkoutList = document.querySelector("#checkoutCartListWrap");

        this.wrapper.addEventListener("click", (evt) => {
            const tg = evt.target as HTMLElement;
            if (!tg.matches('#cart-close') && !tg.matches("#cartContinueShopping")) {
                return;
            }
            this.close();
        })

        this.headerBtn.addEventListener("click", () => {
            this.loadAndShowCartForm();
        });

        Evt.on("change", `[data-numberchangehandler="cart"]`, async (e) => {
            const input = e.currentTarget as HTMLInputElement;
            const productId = input.getAttribute('data-productid');
            await cartForm.updateProductQuantity(productId, input.value);
        });

        document.body.addEventListener("click", this.onBuyButtonClick);
        document.body.addEventListener("click", this.onRemoveButtonClick);
        document.body.addEventListener("click", this.onCheckoutRemoveButtonClick);

        // this.loadAndShowCartForm();
        this.loadAndShowTotalInfo();
    }

    async onBuyButtonClick(evt) {
        const [attrStr, buttonEl] = findAttributeValueInTreeParents(evt.target, "data-productaddtocartbutton");
        if (attrStr === null) {
            return;
        }

        evt.stopPropagation();
        evt.preventDefault();

        setButtonIsDisabled(buttonEl, true);

        const quantity = buttonEl.getAttribute('data-productquantity');

        const prodId = parseInt(attrStr, 10);

        const formData = new FormData();
        formData.set("quantity", quantity);
        formData.set("productId", prodId.toString());

        const response = await fetch('/public/cart/addProductToCart', {
            body: formData,
            headers: {
                "Accept": "application/json",
            },
            method: "POST"
        });

        const json: IApiResult = await response.json();
        console.log(json);

        // setButtonIsDisabled(buttonEl, false);
        this.loadAndShowCartForm();
    }

    async onRemoveButtonClick(evt) {
        const [attrStr, buttonEl] = findAttributeValueInTreeParents(evt.target, "data-productremovefromcartbutton");
        if (attrStr === null) {
            return;
        }

        // evt.stopPropagation();
        evt.preventDefault();

        setButtonIsDisabled(buttonEl, true);

        const productId = attrStr;

        const formData = new FormData();
        formData.set("productId", productId);
        const response = await fetch('/public/cart/removeProductFromCart', {
            body: formData,
            headers: {
                "Accept": "application/json",
            },
            method: "POST"
        });

        const json: IApiResult = await response.json();
        console.log(json);

        setButtonIsDisabled(buttonEl, false);

        this.renderPopupContent(json);
        this.renderTotalInfo(json);
    }


    async updateProductQuantity(productId, quantity) {
        // const [attrStr, buttonEl] = findAttributeValueInTreeParents(evt.target, "data-productremovefromcartbutton");
        // if (attrStr === null) {
        //     return;
        // }

        // evt.stopPropagation();
        // evt.preventDefault();
        //
        // setButtonIsDisabled(buttonEl, true);

        // const productId = attrStr;

        const formData = new FormData();
        formData.set("productId", productId);
        formData.set("quantity", quantity);
        const response = await fetch('/public/cart/setProductQuantity', {
            body: formData,
            headers: {
                "Accept": "application/json",
            },
            method: "POST"
        });

        const json: IApiResult = await response.json();
        // setButtonIsDisabled(buttonEl, false);

        this.renderPopupContent(json);
        this.renderTotalInfo(json);
    }


    async onCheckoutRemoveButtonClick(evt) {
        // const [attrStr, buttonEl] = findAttributeValueInTreeParents(evt.target, "data-productremovefromcartbuttoncheckout");
        // if (attrStr === null) {
        //     return;
        // }
        //
        // evt.stopPropagation();
        // evt.preventDefault();
        //
        // const cartId = parseInt(attrStr, 10);
        //
        // const formData = new FormData();
        // formData.set("cart_route", "removeAtCheckout");
        // formData.set("cart_id", cartId.toString());
        // const url = 'index.php?route=extension/dmv_plus_theme/CartAjax';
        // const response = await fetch(url, {
        //     body: formData,
        //     headers: {
        //         "Accept": "application/json",
        //     },
        //     method: "POST"
        // });
        //
        // const json: IApiResult = await response.json();
        // console.log(json);
        //
        // // this.renderPopupContent(json);
        // this.updateOnCartChange(json);
        // // this.loadCheckoutCartList();
    }

    async submit() {
        const formEl = this.wrapper.querySelector("form");
        const formData = new FormData(formEl);
        const url = 'index.php?route=common/home/callback';

        this.button.setAttribute('disabled', "true");

        const response = await fetch(url, {
            body: formData,
            headers: {
                "Accept": "application/json",
            },
            method: "POST"
        });

        this.button.removeAttribute('disabled');

        const json: IApiResult = await response.json();
        console.log(json);

        let target_id = document.querySelector('[id^="modal-form-block"]');
        if (json['success']) {
            target_id.innerHTML = json['success'];
            // _btn.remove();
            // _btnClose.removeClass('hidden');
        }
    }

    async loadAndShowCartForm() {
        this.open();

        const formData = new FormData();
        const url = '/public/cart/getCartForm';
        const response = await fetch(url, {
            body: formData,
            headers: {
                "Accept": "application/json",
            },
            method: "POST"
        });

        const json: IApiResult = await response.json();
        console.log(json);
        if (json.isSuccess) {

        }

        this.renderPopupContent(json);
        this.renderTotalInfo(json);
    }

    async loadAndShowCartCheckoutForm() {
        const formData = new FormData();
        const url = '/public/cart/getCartCheckoutForm';
        const response = await fetch(url, {
            body: formData,
            headers: {
                "Accept": "application/json",
            },
            method: "POST"
        });

        const json: IApiResult = await response.json();

        const checkoutFormCont = document.querySelector("#checkoutCart");
        if (json.isSuccess) {
            checkoutFormCont.innerHTML = (json as object)["data"]["view_cart_html"];
        }

        const isAnyProduct = (json as object)["data"]["cart"]["positions"].length > 0;
        return isAnyProduct;
    }

    async loadAndShowTotalInfo() {
        const formData = new FormData();
        const url = '/public/cart/getCartShortInfo';
        const response = await fetch(url, {
            body: formData,
            headers: {
                "Accept": "application/json",
            },
            method: "POST"
        });

        const json: IApiResult = await response.json();
        console.log(json);
        if (json.isSuccess) {

        }

        this.updateOnCartChange(json);
    }

    private renderPopupContent(json) {
        if (json.isSuccess) {
            this.popupRoot.innerHTML = json["data"]["view_cart_html"];
        }
    }

    private updateOnCartChange(json) {
        this.renderTotalInfo(json);
        this.updateAddToButtons(json);
    }

    private updateAddToButtons(json) {
        const productsInCartIds: number[] = json["data"]["cart"]["positions"].map(s => s["productId"]);
        console.log('productsInCartIds', productsInCartIds);

        productsInCartIds.forEach(id => {
            const els = document.querySelectorAll(`[data-productaddtocartbutton]`);
            els.forEach(el => {
                const prId = parseInt(el.getAttribute('data-productaddtocartbutton'));
                setButtonIsDisabled(el, productsInCartIds.includes(prId))
                // el.setAttribute('disabled', 'disabled');
            });
        });
        // 
    }

    private renderTotalInfo(json) {
        if (json.isSuccess) {
            this.cartTotal.innerHTML = json["data"]["cart"]["totalFormatted"];
        }
    }

    close() {
        this.wrapper.classList.remove("shown");
        overlay.closeOverlay("cart");
    }

    open() {
        overlay.openOverlay("cart");
        this.wrapper.classList.add("shown");
    }
}

const cartForm = new CartForm();
export { cartForm };
// export function initCartForm() {
//     const cartForm = new CartForm();
//     cartForm.init();
// }