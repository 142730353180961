import "./relinkBlock.scss";

export function initRelinkBlock() {
    const relinking_uls = document.querySelectorAll('.relinking-ul')
    const relinking_titles = document.querySelectorAll('.relinking-title')
    setTimeout(() => {
        for (let i = relinking_uls.length - 1; i >= 0; i--) {
            relinking_uls[i].classList.add('active')
        }
    }, 100)
    for (let i = relinking_uls.length - 1; i >= 0; i--) {
        (function (title) {
            title.addEventListener('click', () => {
                title.nextElementSibling.classList.toggle('active')
            })
        })(relinking_titles[i])
    }
}