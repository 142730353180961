// const overlayBg = document.createElement('div');
// overlayBg.classList.add('modal-overlay');
// overlayBg.onclick = closeOverlay;
// document.body.appendChild(overlayBg);
import {Evt} from "../../helpers/DOM/Evt";

class Overlay {
    overlays: Record<string, Element> = {};

    openOverlay(name) {
        const overlayBg = this.overlays[name];
        overlayBg.classList.add('open');
        this.overlays[name].classList.add('open');
    }

    closeOverlay(name) {
        const overlayBg = this.overlays[name];
        Object.values(this.overlays).forEach(el => el.classList.remove('open'));
        overlayBg.classList.remove('open');
    }

    init() {
        document.querySelectorAll('[data-overlay]').forEach(el => {
            const name = el.getAttribute('data-overlay');
            this.overlays[name] = el;
            // el.innerHTML += `<button class="close" data-closeoverlay="${name}" onclick="this.closeOverlay()">&times;</button>`;
        });

        // Evt.on('click', '[data-closeoverlay]', (event) => {
        //     var name = (event.target as HTMLElement).getAttribute('data-closeoverlay');
        //     console.log(name);
        //     this.closeOverlay(name);
        // });
    }
}

const overlay = new Overlay();
export {overlay};