import {Evt} from "sources/helpers/DOM/Evt";
import "./numberInput.scss";

export class NumberInputWithButtons {
    private incrementOrDecrementValue(e, isIncrement: boolean) {
        e.preventDefault();

        const parent = e.currentTarget.closest('.number-field');
        const numberInput = parent.querySelector(`[type="number"]`);
        const currentVal = parseInt(numberInput.value, 10);

        let newVal = 0;
        if (!isNaN(currentVal)) {
            newVal = isIncrement ? currentVal + 1 : currentVal - 1;
        }

        if (newVal <= 1) {
            newVal = 1;
        }

        numberInput.value = newVal;

        if (numberInput.hasAttribute("data-linktobutton")) {
            const inputAttrVal = numberInput.getAttribute("data-linktobutton");
            const linkedButtonEl = document.querySelector(`[data-linktoquantityinput="${inputAttrVal}"]`);
            linkedButtonEl.setAttribute("data-productquantity", newVal.toString());
        }

        numberInput.dispatchEvent(new Event("change", { bubbles: true }));
    }

    initGlobal() {
        Evt.on("click", ".input-number-button-minus", (e) => {
            this.incrementOrDecrementValue(e, false);
        });
        Evt.on("click", ".input-number-button-plus", (e) => {
            this.incrementOrDecrementValue(e, true);
        });
    }
}
