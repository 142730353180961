'use strict';

// let jQuery = require('jquery');
// require('jquery-migrate');
// let magnificPopup = require('magnific-popup');
// let UIkit = require('uikit/dist/js/uikit-core.js');

// require('Js/jquery.form.min.js');
// require('Js/validation/jquery.validate.js');
// require('Js/validation/localization/messages_ru.js');
// require('Js/validation/additional-methods.js');
//
// require('Css/css-helper-classes.min.css');
// require('Css/site.scss');

import {whenDOMReady} from "../helpers/Other/WhenDOMReady";

// whenDOMReady().then(function () {
// // document.addEventListener('DOMContentLoaded', function(){
//     // let isMobile = false;
//
//     // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent || navigator.vendor || window.opera) ) {
//     //     document.body.className += ' mobile';
//     //     isMobile = true;
//     // }
//
//     // let menuOffcanvas = jQuery('#menu-offcanvas');
//     // if (menuOffcanvas.length === 1) {
//     //     UIkit.offcanvas('#menu-offcanvas', {
//     //         mode:'push',
//     //         overlay:true
//     //     });
//     // }
//
//     // let columnLeft = jQuery('#column-left');
//     // let logoImgBlock = jQuery('#header').find('.logo-wrap');
//
//     // if (window.matchMedia('(max-width: 767px)').matches && columnLeft.length === 1) {
//     //     columnLeft.addClass('uk-offcanvas');
//     //     let logoClone = logoImgBlock.clone().addClass('m_bottom_30');
//     //     columnLeft.children('.column-left-inner').addClass('uk-offcanvas-bar w-75');
//     //     columnLeft.children('.column-left-inner').prepend(logoClone);
//     //
//     //     UIkit.offcanvas('#column-left', {
//     //         mode:'push',
//     //         overlay:true
//     //     });
//     // } else {
//     //     columnLeft.children('.column-left-inner').removeClass('uk-offcanvas-bar w-75');
//     //     columnLeft.removeClass('uk-offcanvas');
//     // }
//
//     if (jQuery('form.validate').length > 0) {
//         jQuery.validator.setDefaults({
//             debug: true
//             , success: 'valid'
//             , resetForm: true
//             , errorElement: 'span'
//         });
//
//         jQuery.validator.addMethod('lettersonly', function (value, element) {
//             return this.optional(element) ||
//                 value.match(/^[a-zA-Z\-\u0400-\u04FF\s]+$/);
//         }, 'Разрешены только буквы и дефис!');
//
//         jQuery.validator.addMethod('phone_number', function (value, element) {
//             return this.optional(element) || value.match(/^[0-9+()-]+$/);
//         }, 'Введите правильный номер телефона.');
//
//         let formContacts = jQuery('form.validate[id="form-contacts"]');
//         if (formContacts.length === 1) {
//             formContacts.validate({
//                 rules: {
//                     name: {
//                         required: true,
//                         normalizer: function (value) {
//                             return jQuery.trim(value);
//                         },
//                         lettersonly: true,
//                         minlength: 3,
//                         maxlength: 32
//                     },
//                     email: {
//                         required: true,
//                         normalizer: function (value) {
//                             return jQuery.trim(value);
//                         },
//                         email: true
//                     },
//                     phone: {
//                         required: function (element) {
//                             if (jQuery.trim(jQuery(element).val()).length === 0) {
//                                 return false;
//                             } else {
//                                 return true;
//                             }
//                         },
//                         normalizer: function (value) {
//                             return jQuery.trim(value);
//                         },
//                         phone_number: true,
//                         minlength: 10,
//                         maxlength: 20
//                     },
//                     enquiry: {
//                         required: true,
//                         normalizer: function (value) {
//                             return jQuery.trim(value);
//                         },
//                         //letterandsomesymbolssonly: true,
//                         minlength: 10,
//                         maxlength: 3000
//                     }
//                 },
//                 submitHandler: function (form) {
//                     form.submit();
//                 }
//             });
//         }
//
//         let formSubscription = jQuery('form.validate[id="form-subscription"]');
//         let formSubscriptionBtn = jQuery('#subscribe-go');
//
//         let formSubscriptionOptions = {
//             url: 'index.php?route=common/home/subscribeSend',
//             type: 'post',
//             dataType: 'json',
//             beforeSubmit: function () {
//                 formSubscriptionBtn.attr('disabled', 'disabled').prop('disabled');
//             },
//             success: function (json) {
//                 let targetId = jQuery('[id^="subscribe-form-block"]');
//
//                 if (json['success']) {
//                     targetId.html(json['success']);
//                     formSubscriptionBtn.remove();
//                 }
//
//                 if (json['error']) {
//                     console.log(json['error']);
//                 }
//             }
//         };
//
//         formSubscription.validate({
//             rules: {
//                 subs_name: {
//                     required: true,
//                     normalizer: function (value) {
//                         return jQuery.trim(value);
//                     },
//                     lettersonly: true,
//                     minlength: 3,
//                     maxlength: 32
//                 },
//                 subs_email: {
//                     required: true,
//                     normalizer: function (value) {
//                         return jQuery.trim(value);
//                     },
//                     email: true
//                 }
//             },
//             messages: {
//                 subs_name: {
//                     required: '<span class="bg_color_white">Это поле необходимо заполнить.</span>',
//                     minlength: jQuery.validator.format('<span class="bg_color_white">Пожалуйста, введите не меньше {0} символов.</span>'),
//                     maxlength: jQuery.validator.format('<span class="bg_color_white">Пожалуйста, введите не больше {0} символов.</span>')
//                 },
//                 subs_email: {
//                     required: '<span class="bg_color_white">Это поле необходимо заполнить.</span>',
//                     email: '<span class="bg_color_white">Пожалуйста, введите корректный адрес электронной почты.</span>'
//                 }
//             },
//             errorElement: 'span',
//             submitHandler: function (form, event) {
//                 event.preventDefault();
//                 jQuery(form).ajaxSubmit(formSubscriptionOptions);
//             }
//         });
//     }
//
//     jQuery('.product-block').each(function () {
//         let _product = jQuery(this);
//         let _mainimageBlock = _product.children('.pb-image');
//         let _mainImage = _mainimageBlock.find('img');
//         let _addimagesBlock = _product.children('.pb-images');
//
//         _addimagesBlock.children('.pbi').each(function () {
//             let _this = jQuery(this);
//             let _thisSrc = _this.data('src');
//             _this.mouseenter(function () {
//                 _mainImage.attr('src', _thisSrc);
//             });
//         });
//     });
//
//
//     if (jQuery('#star_rating_form').length > 0) {
//         jQuery('#star_rating_form input[name="star"]').on('click', function () {
//             let _val = jQuery(this).val();
//             let _parent = jQuery(this).parent('form');
//             let _pid = _parent.find('input[name="pid"]').val();
//
//             jQuery.post(
//                 'index.php?route=module/iblog/updateRating',
//                 {
//                     ratingVal: _val,
//                     pid: _pid
//                 },
//                 function (json) {
//                     if (json['ratingVal']) {
//                         _parent.find('meta[name="ratingValue"]').attr('content', json['ratingVal']);
//                     }
//
//                     if (json['ratingVal_q']) {
//                         _parent.find('meta[name="ratingCount"]').attr('content', json['ratingVal_q']);
//                     }
//
//                     _parent.find('label.star').addClass('disabled');
//                     _parent.find('input[name="star"]').prop('disabled', true).attr('disabled', 'disabled');
//                 }, 'json'
//             );
//         });
//     }
//
//     if (jQuery('#callback').length > 0) {
//         jQuery('#callback').on('click', function () {
//
//             jQuery.magnificPopup.open({
//                 items: {
//                     src: 'index.php?route=common/home/callback',
//                     type: 'ajax'
//                 },
//                 type: 'inline',
//                 callbacks: {
//                     ajaxContentAdded: function () {
//
//                         jQuery('form.validate[id="callback-form"]').each(function () {
//                             let _form = jQuery(this);
//                             let _btn = jQuery('#callback-go');
//                             let _btnClose = jQuery('#callback-close');
//
//                             let form_options = {
//                                 resetForm: true,
//                                 url: 'index.php?route=common/home/callbackSend',
//                                 type: 'post',
//                                 dataType: 'json',
//                                 beforeSubmit: function () {
//                                     _btn.attr('disabled', 'disabled').prop('disabled');
//                                 },
//                                 success: function (json) {
//                                     let target_id = jQuery('[id^="modal-form-block"]');
//
//                                     if (json['success']) {
//                                         target_id.html(json['success']);
//                                         _btn.remove();
//                                         _btnClose.removeClass('hidden');
//                                     }
//
//                                     if (json['error']) {
//                                         console.log(json['error']);
//                                     }
//                                 }
//                             };
//
//                             jQuery.validator.setDefaults({
//                                 debug: true
//                                 , success: 'valid'
//                             });
//
//                             jQuery.validator.addMethod('lettersonly', function (value, element) {
//                                 return this.optional(element) ||
//                                     value.match(/^[a-zA-Z\-\u0400-\u04FF\s]+$/);
//                             }, 'Разрешены только буквы и дефис!');
//
//                             jQuery.validator.addMethod('phone_number', function (value, element) {
//                                 return this.optional(element) || value.match(/^[0-9+()-]+$/);
//                             }, 'Введите правильный номер телефона.');
//
//                             _form.validate({
//                                 rules: {
//                                     callback_name: {
//                                         required: true,
//                                         normalizer: function (value) {
//                                             return jQuery.trim(value);
//                                         },
//                                         lettersonly: true,
//                                         minlength: 3,
//                                         maxlength: 32
//                                     },
//                                     callback_phone: {
//                                         required: true,
//                                         normalizer: function (value) {
//                                             return jQuery.trim(value);
//                                         },
//                                         phone_number: true,
//                                         minlength: 10,
//                                         maxlength: 20
//                                     }
//                                 },
//                                 messages: {},
//                                 errorElement: 'span',
//                                 submitHandler: function (form, event) {
//                                     event.preventDefault();
//                                     jQuery(form).ajaxSubmit(form_options);
//                                 }
//                             });
//                         });
//
//                         jQuery('#callback-go').on('click', function () {
//                             jQuery(this).closest('#popup-cart-inner').find('form.validate').submit();
//                         });
//                     }
//                 }
//             }, 0);
//         });
//     }
//
//     jQuery(document).on('click', '#returnToTop', function (e) {
//         jQuery('body,html').animate({
//             scrollTop: 0
//         }, 888);
//     });
//
//     let cartButton = jQuery('#cart > button');
//     if (cartButton.length > 0) {
//         cartButton.removeAttr('data-toggle').attr('onclick', 'DMV.get_popup_cart(false,\'' + 'show' + '\');');
//     }
//
//     jQuery.each(jQuery('[onclick^=\'DMV.cart.add\']'), function () {
//         let product_id = jQuery(this).attr('onclick').match(/[0-9]+/);
//         jQuery(this).attr('onclick', 'DMV.get_popup_cart(\'' + $(this).attr('onclick').match(/[0-9]+/) + '\',\'' + 'products' + '\');');
//     });
//
//     let main_product_id = jQuery('input[name=\'product_id\']').val();
//     jQuery('#button-cart').unbind('click').attr('onclick', 'DMV.get_popup_cart(\'' + main_product_id + '\',\'' + 'product' + '\');');
//
//     let similarOwl = jQuery('.similar-main-carousel.owl-carousel');
//
//     similarOwl.each(function () {
//         let thisSimilarOwl = jQuery(this);
//
//         thisSimilarOwl.owlCarousel({
//             loop: false,
//             margin: 10,
//             items: 4,
//             dots: false,
//             nav: false,
//             rewind: true,
//             responsive: {
//                 0: {
//                     margin: 0,
//                     items: 1
//                 },
//                 768: {
//                     margin: 5,
//                     items: 2
//                 },
//                 992: {
//                     margin: 5,
//                     items: 3
//                 },
//                 1200: {
//                     margin: 10,
//                     items: 4
//                 }
//             }
//         });
//
//         let _next = thisSimilarOwl.closest('.owl-cat-wrap').find('.rec-next.rec-nav');
//         let _prev = thisSimilarOwl.closest('.owl-cat-wrap').find('.rec-prev.rec-nav');
//
//         _next.click(function () {
//             thisSimilarOwl.trigger('next.owl.carousel');
//         });
//
//         _prev.click(function () {
//             thisSimilarOwl.trigger('prev.owl.carousel');
//         });
//     });
//
// });

// window.addEventListener('resize', function(event) {
//
// });