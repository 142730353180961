import "./offcanvas.scss";

function initOffCanvas() {
    const navbarToggler = document.querySelector('#navbar-toggler');
    const menuClose = document.querySelector('#navbar-toggler-close');
    const menuWrapper = document.querySelector('#menu');
    const overlayEl = document.querySelector('#overlay');
    // const topBannerOverlay = document.querySelector('.top-banner-overlay');

    const toggleOverlay = (isShown) => overlayEl.classList.toggle('--shown', isShown);

    function toggleMenu() {
        navbarToggler.addEventListener('click', () => {
            menuWrapper.classList.add('--opened');
            toggleOverlay(true);
        });

        if (menuClose !== null) {
            menuClose.addEventListener('click', () => {
                menuWrapper.classList.remove('--opened');
                toggleOverlay(false);
            });
        }
    }

    // const menuOpen = document.querySelector('.top-nav .menu-open');
    // const menuClose = document.querySelector('.top-nav .menu-close');
    // const menuWrapper = document.querySelector('.top-nav .menu-wrapper');
    // const topBannerOverlay = document.querySelector('.top-banner-overlay');

    // function toggleMenu() {
    //     menuOpen.addEventListener('click', () => {
    //         menuWrapper.classList.add('is-opened');
    //         topBannerOverlay.classList.add('is-moved');
    //     });

    //     menuClose.addEventListener('click', () => {
    //         menuWrapper.classList.remove('is-opened');
    //         topBannerOverlay.classList.remove('is-moved');
    //     });
    // }

    toggleMenu();
}

export { initOffCanvas };