import "sources/styles/modules/subscribeForm.scss";
import {overlay} from "./overlay";
import {IApiResult} from "../Api/apiResult";
import {validator} from "../../components/Blocks/Form/Validator";

class SubscribeForm {
    private button: Element;
    private buttonClose: Element;
    private formEl: HTMLFormElement;

    init() {
        this.button = document.querySelector("#subscribe-go");
        this.formEl = document.querySelector<HTMLFormElement>('#form-subscription');
        if(this.button === null) {
            return;
        }

        this.button.addEventListener("click", () => {
            this.submit();
        });
    }

    async submit() {
        const formEl = document.querySelector("#form-subscription") as HTMLFormElement;
        const formData = new FormData(formEl);

        this.toggleLoading(true);

        const response = await fetch('/public/form/submitSubscribe', {
            body: formData,
            headers: {
                "Accept": "application/json",
            },
            method: "POST"
        });

        this.toggleLoading(false);

        const json: IApiResult = await response.json();
        console.log(json);

        validator.fromValidationResult(json.validationResult, this.formEl);
        // let target_id = document.querySelector('#subscribe-form-block');
        if (json.isSuccess) {
            // this.popupRoot.innerHTML = json["data"]["view_cart_html"];
            this.formEl.style.display = "none";
            document.querySelector<HTMLDivElement>('.subscribeForm-desc').style.display = "none";
            document.querySelector<HTMLFormElement>('.subscribeForm-heading').innerText = json['message'];
        } else {

        }

        // if (json['success']) {
        //     target_id.innerHTML = json['success'];
        //     this.button.remove();
        // }
    }

    toggleLoading(isLoading: boolean) {
        if(isLoading) {
            this.button.setAttribute('disabled', '');
        } else {
            this.button.removeAttribute('disabled');
        }
    }
}

export function initSubscribeForm() {
    const form = new SubscribeForm();
    form.init();
}

//             messages: {
//                 subs_name: {
//                     required: '<span class="bg_color_white">Это поле необходимо заполнить.</span>',
//                     minlength: jQuery.validator.format('<span class="bg_color_white">Пожалуйста, введите не меньше {0} символов.</span>'),
//                     maxlength: jQuery.validator.format('<span class="bg_color_white">Пожалуйста, введите не больше {0} символов.</span>')
//                 },
//                 subs_email: {
//                     required: '<span class="bg_color_white">Это поле необходимо заполнить.</span>',
//                     email: '<span class="bg_color_white">Пожалуйста, введите корректный адрес электронной почты.</span>'
//                 }
//             },