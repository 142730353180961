import {on, off, fire} from 'delegated-events';

const Evt = {
    on: on,
    off: off,
    fire: fire
}
export {Evt};
// export {on, off, fire};

// class EventHelper {
//     private activeEvents: {};
//     private publicAPIs: {};
//
//     constructor() {
//         this.activeEvents = {};
//         this.publicAPIs = {};
//         this.eventHandler = this.eventHandler.bind(this);
//     }
//
//     getIndex(arr, selector, callback) {
//         for (let i = 0; i < arr.length; i++) {
//             if (
//                 arr[i].selector === selector &&
//                 arr[i].callback.toString() === callback.toString()
//             ) return i;
//         }
//         return -1;
//     }
//
//     doRun(target, selector) {
//         if ([
//             '*',
//             'window',
//             'document',
//             'document.documentElement',
//             window,
//             document,
//             document.documentElement
//         ].indexOf(selector) > -1) return true;
//         if (typeof selector !== 'string' && selector.contains) {
//             return selector === target || selector.contains(target);
//         }
//         return target.closest(selector);
//     }
//
//     eventHandler(event) {
//         if (!this.activeEvents[event.type]) return;
//         this.activeEvents[event.type].forEach((listener) => {
//             if (!this.doRun(event.target, listener.selector)) return;
//             listener.callback(event);
//         });
//     }
//
//     on(types: string, selector: string, callback: Function) {
//         types.split(',').forEach((type) => {
//             type = type.trim();
//
//             if (!this.activeEvents[type]) {
//                 this.activeEvents[type] = [];
//                 window.addEventListener(type, this.eventHandler, true);
//             }
//
//             this.activeEvents[type].push({
//                 selector: selector,
//                 callback: callback
//             });
//
//         });
//
//     }
//
//     off(types, selector, callback) {
//         types.split(',').forEach((type) => {
//             type = type.trim();
//             if (!this.activeEvents[type]) return;
//
//             if (this.activeEvents[type].length < 2 || !selector) {
//                 delete this.activeEvents[type];
//                 window.removeEventListener(type, this.eventHandler, true);
//                 return;
//             }
//
//             const index = this.getIndex(this.activeEvents[type], selector, callback);
//             if (index < 0) return;
//             this.activeEvents[type].splice(index, 1);
//         });
//
//     }
//
//     get() {
//         let obj = {};
//         for (let type in this.activeEvents) {
//             if (this.activeEvents.hasOwnProperty(type)) {
//                 obj[type] = this.activeEvents[type];
//             }
//         }
//         return obj;
//     };
// }
//
// const Evt = new EventHelper();
// export {Evt};