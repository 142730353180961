function whenDOMReady() {
    return new Promise<void>(function(res) {
        if (document.readyState === "complete" || document.readyState === "interactive") {
            res();
        } else {
            window.addEventListener('DOMContentLoaded', function () {
                res();
            });
        }
    });
}

export { whenDOMReady };