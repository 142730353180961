import "sources/styles/style.scss";
import 'sources/styles/layout.scss';

import {init_search} from "./../dmvplus/components/topSearch";
import {whenDOMReady} from "../helpers/Other/WhenDOMReady";
import {initRelinkBlock} from "../components/Elements/relinkBlock";
import {initSubscribeForm} from "../modules/Form/subsribeForm";
import {initCallbackForm} from "../modules/Form/callbackForm";
import {overlay} from "../modules/Form/overlay";
// import {initCartForm} from "../modules/Form/cartForm";
import {initOffCanvas} from "sources/dmvplus/components/offcanvas";
import "../modules/oldSite";
import {Evt} from "../helpers/DOM/Evt";
import {initToTopButton} from "../dmvplus/components/toTop";
import {NumberInputWithButtons} from "../dmvplus/components/numberInput";
import { cartForm } from "sources/modules/Form/cartForm";

async function initProductThumbHover() {
    await whenDOMReady();
    Evt.on("mouseover", ".productList-item", (evt) => {
        evt.currentTarget.classList.add("withHover");
    });
    Evt.on("mouseout", ".productList-item", (evt) => {
        evt.currentTarget.classList.remove("withHover");
    });
}

class PublicLayout {
    isRegistered: boolean;

    register() {
        this.isRegistered = true;
    }

    init() {
        init_search(document.getElementById('header-search-1'))
        initToTopButton();
        overlay.init();

        initRelinkBlock();
        initSubscribeForm();
        initCallbackForm();
        // initCartForm();

        cartForm.init();

        initProductThumbHover();
        initOffCanvas();
        const numberInputs = new NumberInputWithButtons();
        numberInputs.initGlobal();
    }

    onResize(evt: Event) {

    }
}

const publicLayout = new PublicLayout();

whenDOMReady().then(function () {
    publicLayout.init();
});
//
export {publicLayout};