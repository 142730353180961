import "sources/styles/modules/callbackForm.scss";
import {overlay} from "./overlay";
import {IApiResult} from "../Api/apiResult";
import IMask from "imask";
import {maskPhoneOptions} from "../../helpers/shared";
import {validator} from "../../components/Blocks/Form/Validator";

class CallbackForm {
    private wrapper: Element;
    private button: Element;
    private buttonClose: Element;
    private headerBtn: Element;
    private isMaskInited: boolean;
    private formEl: HTMLFormElement;

    init() {
        this.headerBtn = document.querySelector("#callback");
        this.wrapper = document.querySelector("#popup-callback-wrapper");
        this.button = document.querySelector("#callback-go");
        this.buttonClose = document.querySelector("#callback-close");
        this.formEl = this.wrapper.querySelector("form");

        this.button.addEventListener("click", () => {
            this.submit();
        });

        this.buttonClose.addEventListener("click", () => {
            this.close();
        });

        this.headerBtn.addEventListener("click", () => {
            if (this.isMaskInited !== true) {
                const phoneEl = document.querySelector<HTMLInputElement>('#callback_phone');
                const maskPhone = IMask(phoneEl, maskPhoneOptions);
                this.isMaskInited = true;
            }

            this.open();
        });
    }

    async submit() {
        const formData = new FormData(this.formEl);
        this.toggle(true);

        const response = await fetch('/public/form/submitCallback', {
            body: formData,
            headers: {
                "Accept": "application/json",
            },
            method: "POST"
        });

        const json = await response.json() as IApiResult;

        validator.fromValidationResult(json.validationResult, this.formEl);
        this.toggle(false);

        let target_id = document.querySelector('#modal-form-block-callback');
        if (json.isSuccess) {
            target_id.innerHTML = json.message;
            this.button.remove();

            // _btn.remove();
            // _btnClose.removeClass('hidden');
        }
    }

    close() {
        this.wrapper.classList.remove("shown");
        overlay.closeOverlay("callback");
    }

    open() {
        overlay.openOverlay("callback");
        this.wrapper.classList.add("shown");
    }

    toggle(isLoading: boolean) {
        isLoading ? this.button.setAttribute('disabled', '') : this.button.removeAttribute('disabled');
    }
}

export function initCallbackForm() {
    const callbackForm = new CallbackForm();
    callbackForm.init();
}