function initToTopButton() {
    const scrollToTopBtn: HTMLAnchorElement = document.querySelector('#returnToTop');

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    function toggleVisibility() {
        if (window.scrollY > 400) {
            scrollToTopBtn.style.display = 'block';
        } else {
            scrollToTopBtn.style.display = 'none';
        }
    }

    scrollToTopBtn.addEventListener('click', scrollToTop);
    window.addEventListener('scroll', toggleVisibility, {passive: true});
}

export {initToTopButton};