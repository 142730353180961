import "./topSearch.scss";

export function init_search(wrapper) {

    const input = wrapper.querySelector('input')
    const list = wrapper.querySelector('.header-search-list')
    const ico = wrapper.querySelector('.header-search-ico')

    // let timeout = 0;
    let timeout;

    input.addEventListener('input', find)
    input.addEventListener('keyup', (e) => {
        if (e.key == 'Enter')
            find(e);
    })

    const iconPath = input.getAttribute('data-iconsrc');
    ico.addEventListener('click', find)

    const toggleLoadCls = (isLoad) => wrapper.classList.toggle('--loading', isLoad);

    function find(e) {
        const str = input.value

        toggleLoadCls(str.length > 1);

        if (str.length > 1) {
            clearTimeout(timeout)
            list.style.display = ''
            list.innerHTML = `<div class="header-search-list-link"><img src="${iconPath}"></div>`
            timeout = setTimeout(async () => {
                const response = await fetch('/public/site/search?searchQuery=' + str);
                if (response.ok) {
                    // const text = await response.text()
                    // list.innerHTML = text
                    toggleLoadCls(false);

                    const items = await response.json() as any[];
                    const itemsHtml = items.map(item => {
                        return `<a target='_blank' href='${item.url}'>${item.name}</a></br>`;
                    });
                    list.innerHTML = `<ul>${itemsHtml.join('')}</ul>`;
                }
            }, 2000)
        } else {
            list.style.display = 'none'
            list.innerHTML = ''
            clearTimeout(timeout)
        }
    }

    document.body.addEventListener('click', (e) => {
        const trg = e.target as HTMLElement;
        const elms = [
            trg,
            trg.parentElement,
            trg.parentElement.parentElement,
        ]
        let has_class = false
        for (var i = elms.length - 1; i >= 0; i--) {
            if (elms[i].classList.contains('header-search')) {
                has_class = true
                break
            }
        }
        if (!has_class) list.style.display = 'none'
    })
}