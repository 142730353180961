import {IActionValidationResult} from "sources/modules/Api/apiResult";
import "./Validator.scss";

export class Validator {
    fromValidationResult(validState: IActionValidationResult, formEl: HTMLFormElement) {
        const alreadyInvalidInputs = Array.from(formEl.querySelectorAll<HTMLInputElement>(".-invalid"));
        const alreadyInvalidWrappers = Array.from(formEl.querySelectorAll<HTMLInputElement>(".-invalid-wrapper"));
        const alreadyInvalidMessages = Array.from(formEl.querySelectorAll(".-invalid-message"));

        alreadyInvalidMessages.forEach(s => s.classList.remove("-invalid-message"));
        alreadyInvalidInputs.forEach(s => s.classList.remove("-invalid"));
        alreadyInvalidWrappers.forEach(s => s.classList.remove("-invalid-wrapper"));

        validState.errors.forEach(er => {
            let name = er.propertyPath;
            // if (name.split("[").length - 1 === 1 && name.split("]").length - 1 === 1) {
            //     let t1 = name.replaceAll("[", "").replaceAll("]", "");
            //     if (t1.length === (name.length - 2)) name = t1;
            // }
            name = name.replace("[", '').replace("]", '');

            let input = formEl.elements[name];
            let elMessage = null;
            if (!input) {
                elMessage = formEl.querySelector(`[data-validation="${name}"]`);
                this.updateMsg(elMessage, false, er.message);
            } else if (input instanceof RadioNodeList) {
                Array.from(input).forEach((s: HTMLInputElement) => this.update(s, false, er.message));
                // this.updateMsg(elMessage, false, er.message);
            } else {
                this.update(input, false, er.message);
            }

            let ind = alreadyInvalidInputs.indexOf(input);
            if (ind !== -1) {
                alreadyInvalidInputs.splice(ind, 1);
            }
        });

        // alreadyInvalidInputs.forEach(input => {
        //     this.update(input, null, true, "");
        // });
    }

    private update(input: HTMLInputElement, isValid: boolean, error: string) {
        if (!input) {
            return;
        }

        // if (input instanceof RadioNodeList) {
        //     // Array.from(input).forEach((inp => (inp as HTMLInputElement).classList.toggle("-invalid", !isValid)));
        //     // let wrapper = (input[0] as HTMLInputElement).closest(".form-group");
        //     this.updateMsg(input[0].closest('') as HTMLInputElement, false, "");
        //     // if (wrapper) {
        //     //     wrapper.classList.toggle("-invalid-wrapper", !isValid);
        //     // }
        //     return;
        // }

        input.classList.toggle("-invalid", !isValid);
        let wrapper = input.closest(".form-group");
        if (wrapper) {
            wrapper.classList.toggle("-invalid-wrapper", !isValid);
        }
    }

    private updateMsg(el: Element, isValid: boolean, error: string) {
        el.classList.toggle("-invalid-message", !isValid);
    }
}

const validator = new Validator();
export {validator};